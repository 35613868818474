import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOts, getOtsLength, isInternalAdmin } from '../../okta/ls';
import { Const, capitalize } from '../utils';

import * as FDS from '@arctravel/react-fds/lib';

declare const window: any;

export const Header = (props: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const notificationsRef = useSelector((state: any) => state.notificationsRef);
    const customersRef: any = useSelector((state: any) => state.customersRef);
    const [custRef, setCustRef] = useState(customersRef);
    const [user, setUser] = useState(getOtsLength() > 0 ? capitalize(getOts()?.idToken?.claims?.name) : "");

    // object for FDS Header
    const [config, setConfig] = useState({
        productname: "Discovery Point",
        username: user,
        links: [],
        helpLink: "",
        helpTarget: "_blank",
        showClose: false,
        closeLabel: "Logout",
        notifications: [{
            title: "Report 1",
            description: "Successfully Executed"
        }, {
            title: "Report 2",
            description: "Successfully Executed"
        }],
        utilityLinks: [
            (<Link className='nav-link' to={`/profile`}>My Profile</Link>),
            (<Link to={`/`} className="nav-link" onClick={() => props.logout()}>Logout</Link>)
        ]
    });

    useEffect(() => {
        setCustRef(customersRef);
    }, [customersRef]);

    const initializeHeader = useCallback(() => {
        if (user !== "") {
            const tempLinks: any = [
                {
                    label: "Report Insights",
                    items: [
                        custRef?.customer?.modules?.indexOf("JOURNEY INSIGHTS ESTIMATES") >= 0 ?
                            {
                                menuItem: [(<Link to={`/reports/${window.ENV.props.reportTitles.JOURNEY.id}`}>Journey Insights</Link>)],
                                items: [
                                    (<Link to={`/reports/${window.ENV.props.reportTitles.JOURNEY.id}`} className="nav-link">Ticketed</Link>),
                                    (<Link to={`/reports/${window.ENV.props.reportTitles.JOURNEY_ESTIMATES.id}`} className="nav-link">Estimates</Link>)
                                ]
                            } :
                            (custRef?.customer?.modules?.indexOf("JOURNEY INSIGHTS") >= 0 && <Link to={`/reports/${window.ENV.props.reportTitles.JOURNEY.id}`} className="nav-link">Journey Insights</Link>)
                        ,
                        (custRef?.customer?.modules?.indexOf("INTERACTIVE INSIGHTS") >= 0 && <Link to={`/reports/${window.ENV.props.reportTitles.INTERACTIVE.id}`} className="nav-link">Interactive Insights</Link>),
                        (custRef?.customer?.modules?.indexOf("ROUTING INSIGHTS") >= 0 && <Link to={`/reports/${window.ENV.props.reportTitles.ROUTING.id}`} className="nav-link">Routing Insights</Link>)
                    ]
                },
                {
                    label: "BI Insights",
                    items: [
                        (custRef?.customer?.modules?.indexOf("AGENCY PERFORMANCE") >= 0 && <Link to="/reports/AGENCY_PERFORMANCE" className="nav-link">Agency Performance</Link>),
                        (custRef?.customer?.modules?.indexOf("BENCHMARK") >= 0 && <Link to={`/reports/${window.ENV.props.reportTitles.BENCHMARK.id}`} className="nav-link">Agency Benchmark</Link>),
                        (custRef?.customer?.modules?.indexOf("ARCCI") >= 0 && <Link to={`/reports/${window.ENV.props.reportTitles.ARCCI.id}`} className="nav-link">ARC CI</Link>),
                        /*(custRef?.customer?.modules?.indexOf("ARCCI") >= 0 && {
                            menuItem: [(<Link to={`/reports/${window.ENV.props.reportTitles.ARCCI.id}`}>ARC CI &nbsp; </Link>)],
                            items: [
                                (<Link to={`/reports/${window.ENV.props.reportTitles.ARCCI.id}`} className="nav-link">DRA Summary</Link>),
                                (<Link to={`/reports/${window.ENV.props.reportTitles.ARCCIDS.id}`} className="nav-link">DRA Detail</Link>)
                            ]
                        }),*/
                        (custRef?.customer?.modules?.indexOf("CARRIER DASHBOARD") >= 0 && <Link to="/reports/CARRIER_DASHBOARD" className="nav-link">Carrier Dashboard</Link>),
                    ]
                },
                {
                    label: "Groupings",
                    items: [
                        (custRef?.customer?.modules?.indexOf("CUSTOM GROUPINGS") >= 0 && <Link to="/groupings/airport" className="nav-link">Custom Groupings</Link>)
                    ]
                },
                {
                    label: "Report Center",
                    items: [
                        (custRef?.customer?.modules?.indexOf("REPORTING") >= 0 && <Link to="/hub/0" className="nav-link">Report History</Link>),
                        (custRef?.customer?.modules?.indexOf("REPORTING") >= 0 && <Link to="/hubTemplates" className="nav-link">Saved Templates</Link>)
                    ]
                },
                {
                    label: "Reference Data",
                    items: [
                        (custRef?.customer?.modules?.indexOf("REFERENCE DATA") >= 0 && <Link to="/ref/airline" className="nav-link">Reference Data</Link>)
                    ]
                }
            ];

            const links = tempLinks.filter((link: any) => link.items?.some((item: any) => item !== false))

            if ((isInternalAdmin() && (custRef.viewAs.role === Const.INTERNAL_ADMIN || custRef.viewAs.role === Const.EXTERNAL_ADMIN)) ||
                getOts()?.idToken?.claims?.role.indexOf(Const.EXTERNAL_ADMIN) >= 0) {
                links.push({
                    menuItem: [(<Link className='adminLink' to="/admin">Admin</Link>)],
                    items: [
                        (<Link to="/admin/customerinfo" className="nav-link">Customers</Link>),
                        (isInternalAdmin() && <Link to="/admin/messages" className="nav-link">Important Messages</Link>)
                    ]
                })
            }
            //const helpDoc = custRef.customer?.modules?.indexOf("ARCCI") >= 0 && custRef.customer?.modules?.length <= 2 ? "/help_ci.pdf" : "/help.pdf"
            setConfig((prev: any) => { return { ...prev, username: user, links: links, helpLink: window.ENV.help } });
        }
    }, [user, custRef]);

    useEffect(() => {
        setUser(capitalize(props.user));
        initializeHeader();
    }, [props.user, initializeHeader]);

    const selectedNotif = (notif: any) => {
        if (notif.clear === "ONE") {
            dispatch({ type: "REMOVE_NOTIFICATION", data: notif.description });
        } else if (notif.clear === "ALL") {
            dispatch({ type: "REMOVE_ALL_NOTIFICATIONS" });
        } else if (notif.description) {
            history.push('/blank');
            setTimeout(() => {
                history.push('/hub/' + notif.description);
            }, 10)
        }
    };

    useEffect(() => {
        const notifs: any[] = [];
        if (user !== "") {
            for (let i = 0; i < notificationsRef.length; i++) {
                notifs.push({
                    title: notificationsRef[i].title,
                    description: notificationsRef[i].reportName,
                    status: notificationsRef[i].status
                })
            }
            setConfig((prev: any) => {
                prev.notifications.length = 0;
                prev.notifications = [...notifs];
                return { ...prev };
            });
        }
    }, [notificationsRef, user]);

    useEffect(() => {
        console.log("User Logged-in");
    }, [config]);

    return (<FDS.FDSHeader config={config} onNotif={selectedNotif} onClose={() => props.logout()} />);
};