import axios from 'axios';
import { getSavedGroupingsAction } from '../groupings';
import { getAppMessageAction } from "../messages";
import { clearLocalStorage, unzipJson } from '../../../components/utils'
// import store from "../..";

declare const window: any;

const count: any = {};

const read = async (url: string, index: number, dispatch: any, init: boolean, isAgencyField: boolean) => {
    return axios.get(url + (isAgencyField ? "" : index), {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
            'Content-Type': 'application/json'
        }
    }).then(async (response: any) => {
        if (Object.keys(response.data).indexOf("base64(zip(o))") >= 0) {
            response.data = unzipJson(response.data["base64(zip(o))"]);
        }

        if (init) {
            const pages = Math.ceil(response.data.totalRecords / 25000);
            if (pages > 1) {
                Promise.all(Array.from(Array(pages - 1).keys()).map(async (field: any) => {
                    return read(url, field + 1, dispatch, false, isAgencyField);
                })).then(data => {
                    setTimeout(() => {
                        console.log("ALL LOADED.....................")
                        //dispatch({ type: 'DISTINCT_REFERENCE_DATA' });
                        dispatch({ type: 'DONE' });
                    }, 1000)
                });
            } else {
                setTimeout(() => {
                    console.log("ALL LOADED from ELSE.....................")
                    dispatch({ type: 'DONE' });
                }, 1000)
            }
        }

        // Filter Data based on user access and config
        // This feature can be executed if needed in future.
        /*
        const uData = getOts()?.idToken;
        const customerObj = store.getState().customersRef.customer;*/

        // To test
        // customerObj.geography = {
        //     country: ['US:COUNTRY_CD', 'IN:COUNTRY_CD'],
        //     region: ['EUR:IATA_SUBZNE1_CD', 'SOA:IATA_SUBZNE1_CD'],
        //     airport: ["LHR", "GRU", 'BWI'],
        //     city: ["DFW"]
        // };

        // (customerObj?.geography ? Object.keys(customerObj.geography) : []).forEach((geographyKey: any) => {
        //     const globalRegion: any = {
        //         "SWP:IATA_SUBZNE1_CD": 'Southwest Pacific',
        //         "SEA:IATA_SUBZNE1_CD": 'Southeast Asia',
        //         "JAK:IATA_SUBZNE1_CD": 'Japan/Korea',
        //         "SAS:IATA_SUBZNE1_CD": 'South Asia Subcontinent',

        //         "NOA:IATA_SUBZNE1_CD": 'North America',
        //         "SOA:IATA_SUBZNE1_CD": 'South America',
        //         "CEM:IATA_SUBZNE1_CD": 'Central America',
        //         "CAR:IATA_SUBZNE1_CD": 'Caribbean',

        //         "AFR:IATA_SUBZNE1_CD": 'Africa',
        //         "MDE:IATA_SUBZNE1_CD": 'Middle East',
        //         "EUR:IATA_SUBZNE1_CD": 'Europe',
        //     }
        //     const geog = customerObj.geography[geographyKey];
        //     if (geographyKey === 'country' && Object.keys(customerObj.geography).indexOf('region') < 0) {
        //         if (url.indexOf("entity=region") >= 0 && geog.length > 0) {
        //             response.data.results = response.data.results.filter((refData: any) => geog.indexOf(refData.code) >= 0 || geog.indexOf(refData.parent) >= 0);
        //         }
        //     } else if (geographyKey === 'region' && Object.keys(customerObj.geography).indexOf('country') < 0) {
        //         if (url.indexOf("entity=region") >= 0 && geog.length > 0) {
        //             const geoRegion = geog.map((item: string) => globalRegion[item])
        //             response.data.results = response.data.results.filter((refData: any) => geog.indexOf(refData.code) >= 0 || geog.indexOf(refData.parent) >= 0 || geoRegion.indexOf(refData['global region']) >= 0);
        //         }
        //     } else if (geographyKey === 'region' && Object.keys(customerObj.geography).indexOf('country') >= 0) {
        //         if ((url.indexOf("entity=region") >= 0 && geog.length > 0)) {
        //             const geoCountry: any = []
        //             customerObj.geography.country.forEach((country: any) => geoCountry.push(country))
        //             const geoRegion = geog.map((item: string) => globalRegion[item])
        //             response.data.results = response.data.results.filter((refData: any) => geog.indexOf(refData.code) >= 0 || geog.indexOf(refData.parent) >= 0 || geog.indexOf(refData.parent) >= 0 || geoCountry.indexOf(refData.code) >= 0 || geoCountry.indexOf(refData.parent) >= 0 || geoRegion.indexOf(refData['global region']) >= 0);
        //         }
        //     } else {
        //         if ((url.indexOf("entity=" + geographyKey) >= 0 && geog.length > 0)) {
        //             response.data.results = response.data.results.filter((refData: any) => geog.indexOf(refData.code) >= 0 || geog.indexOf(refData.parent) >= 0 || geog.indexOf(refData.code + ":COUNTRY_CD") >= 0 || geog.indexOf(refData.code + ":CITY_CD") >= 0);
        //         }
        //     }

        // })
        //END: // Filter Data based on user access and config

        dispatch({
            type: 'REFERENCE_DATA',
            field: (isAgencyField ? url.substring(0, url.indexOf("/?code") > 0 ? url.indexOf("/?code") : url.length).replace("agency", "?entity=agency&pageIndex=") : url) + "0",
            data: response.data.results
        });

    }).catch(err => {
        // Timeout, Try 3 times
        if (err.response?.status === 504) {
            count[index] = count[index] ? count[index] + 1 : 1;
            if (count[index] <= 3) {
                read(url, index, dispatch, false, isAgencyField);
                return;
            }
        }

        if (axios.isCancel(err)) {
            console.log('Previous request canceled, new request is send', err.message);
        } else {
            console.log("ERROR1:", err);
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
        }

        const t = (+new Date() - (+new Date(localStorage.getItem('expiresAt') + ""))) / 1000 / 60 / 60;
        if (err.response?.status === 401 && (isNaN(t) || t > 1)) {
            clearLocalStorage();
            setTimeout(() => {
                window.location.href = "/";
                window.location.reload();
            }, 1000);
        }
    });
}

export const getReferencesAction = (isAgency?: boolean) => {
    return async (dispatch: any) => {
        dispatch({ type: 'CLEAR_REFERENCE_DATA' });
        localStorage.setItem("isTimeoutShowed", "false");
        dispatch(getAppMessageAction());
        await Promise.all(Object.keys(window.ENV.references).map(async (field: string, i: number) => {
            const url = (isAgency && field === "agency") ?
                window.ENV.references[field].replace("?entity=agency&pageIndex=0", "agency") :
                window.ENV.references[field];
            const newURL = (isAgency && field === "agency") ? url : url.substring(0, url.indexOf("pageIndex=") + 10);
            return read(newURL, 0, dispatch, true, (isAgency && field === "agency" ? true : false));
        }));
        dispatch(getSavedGroupingsAction())
    }
};

export const getMyAgency = (agency: string) => {
    return async (dispatch: any) => {
        dispatch({ type: 'CLEAR_AGENCY_REFERENCE_DATA' });
        const isAgency = agency.length > 0 ? true : false;

        const url = isAgency ?
            window.ENV.references.agency.replace("?entity=agency&pageIndex=0", ("agency/?code=" + agency)) :
            window.ENV.references.agency;

        const newURL = isAgency ? url : url.substring(0, url.indexOf("pageIndex=") + 10);
        dispatch({ type: '' });
        await Promise.all([read(newURL, 0, dispatch, true, isAgency ? true : false)]);
        dispatch(getSavedGroupingsAction());
    }
}